<template>
  <section v-if="products.length">
    <div class="product_cards">
      <article
          v-for="(product, productIndex) in products" :key="product.id"
          class="card active-link"
      >
        <router-link
            :to="getLocation && getLocation.city ? {name: 'Product', params: {id: product.slug}} : ''"
            class="card_link" @click="googleEvent(product.id)"
        >
          <div class="card_product_img">
            <img v-lazy="product.image" :srcset="product.srcset"
                 :sizes="$store.state.sizes" :alt="product.name">
          </div>
          <div class="card_content">
            <h2 class="card_title">{{ product.name }}</h2>
            <p class="card_category">{{ product.public_categ[0].product_public_category.name }}</p>
            <div class="card_cart-container"
                 v-if="getLocation && getLocation.city !== null && getLocation.city !=='null'">
              <p>à partir <span class="product_price">{{ product.lower_price }}€</span></p>
            </div>
          </div>
        </router-link>
				<div v-if="!getLocation || !getLocation.city" class="card_overlay">
					<img class="overlay__img" v-lazy="delivery" alt="delivery">
					<div class="overlay__text">
						<h3>Où livrons-nous ?</h3>
						<p>Les délais et tarifs de transport varient en fonction de votre zone géographique.
							Merci de renseigner votre code postal de livraison.</p>
					</div>
					<div class="overlay__location">
						<form class="location" @submit.prevent="userLocation">
							<AddressAutocomplete
								:id="'cardLocation' + product.id"
								placeholder="votre adresse"
								classname="input"
								:set-location="true"
								@blur="overlay(productIndex, false)"
								@focus="overlay(productIndex, true)"
							/>
							<button aria-label="validate" class="button full" type="submit">
								<svg width="52" height="82" viewBox="0 0 52 82" fill="none"
								     xmlns="http://www.w3.org/2000/svg">
									<path
										d="M1.84137 75.4239C11.4161 63.4726 24.1693 53.9096 34.931 42.8418C35.9512 41.7933 36.9617 40.7447 37.9427 39.6574C39.424 38.0166 40.8269 36.3467 42.1807 34.6283C43.2402 35.638 44.2997 36.5992 45.3592 37.5603C47.1839 39.1914 49.087 40.7059 50.9902 42.1719C50.1858 43.0068 49.3813 43.8515 48.5769 44.6476C46.713 46.5311 44.7607 48.5019 42.8183 50.4339C31.8898 61.4629 19.892 72.9093 6.75625 80.4626C3.41099 82.3946 -0.826992 78.7442 1.84137 75.4336V75.4239Z"
										fill="white"/>
									<path
										d="M9.17935 1.83266C21.2949 11.3083 30.9186 23.978 42.1905 34.6283C43.25 35.638 44.3095 36.5992 45.369 37.5603C47.1937 39.1914 49.0968 40.7059 51 42.1719C50.1956 43.0068 49.3911 43.8515 48.5867 44.6476C46.7228 46.5311 44.7706 48.5019 42.8281 50.4339C41.9845 49.6378 41.131 48.8417 40.3266 48.0456C38.5509 46.3272 36.7655 44.6088 34.9408 42.8418C23.6297 31.939 11.8477 19.8615 4.0977 6.69667C2.14548 3.38604 5.83409 -0.808081 9.17935 1.83266Z"
										fill="white"/>
									<path
										d="M1.84137 75.4239C11.4161 63.4726 24.1693 53.9096 34.931 42.8418C35.9512 41.7933 36.9617 40.7447 37.9427 39.6574C39.424 38.0166 40.8269 36.3467 42.1807 34.6283C43.2402 35.638 44.2997 36.5992 45.3592 37.5603C47.1839 39.1914 49.087 40.7059 50.9902 42.1719C50.1858 43.0068 49.3813 43.8515 48.5769 44.6476C46.713 46.5311 44.7607 48.5019 42.8183 50.4339C31.8898 61.4629 19.892 72.9093 6.75625 80.4626C3.41099 82.3946 -0.826992 78.7442 1.84137 75.4336V75.4239Z"
										stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									<path
										d="M9.17935 1.83266C21.2949 11.3083 30.9186 23.978 42.1905 34.6283C43.25 35.638 44.3095 36.5992 45.369 37.5603C47.1937 39.1914 49.0968 40.7059 51 42.1719C50.1956 43.0068 49.3911 43.8515 48.5867 44.6476C46.7228 46.5311 44.7706 48.5019 42.8281 50.4339C41.9845 49.6378 41.131 48.8417 40.3266 48.0456C38.5509 46.3272 36.7655 44.6088 34.9408 42.8418C23.6297 31.939 11.8477 19.8615 4.0977 6.69667C2.14548 3.38604 5.83409 -0.808081 9.17935 1.83266Z"
										stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</button>
						</form>
					</div>
				</div>
			</article>
		</div>
	</section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
const AddressAutocomplete = () => import ("@/components/AddressAutocomplete.vue")
import {getAPI} from "@/services/axios-api";
import {delivery} from '@/assets-2/header/delivery.webp';

export default {
  name: "ProductCard",
  components: {
    AddressAutocomplete,
  },
  props: {
    products: {
      required: true,
    },
  },
  updated() {
    this.completeCity();
  },
  data() {
    return {
      delivery,
      loc: {
        longitude: null,
        latitude: null,
        zip: null,
        country: null,
        city: null,
      },
    }
  },
  methods: {
		...mapActions('google', ['trackEvent']),
    replace: async function (event, str) {
      await getAPI.get('/ecommerce/image/viewer/', {
        params: {
          url: str,
        }
      }).then(r => event.target.src = r.data.img)
    },
    googleEvent: function (productId) {
      this.$gtag.event('Product', {produit: productId, time: new Date()})
    },
    userLocation: async function () {
			await this.trackEvent({
				action: 'select_location',
				eventParams: {'action': 'user_location', 'value': this.loc.city}
			})
      this.$store.commit('auth/setLocated', true);
    },
    completeCity: function () {
      let autocomplete
      if (this.$refs.location) {
        this.$refs.location.forEach(e => {
          autocomplete = new google.maps.places.Autocomplete(e, {
            componentRestrictions: {country: ["fr", "ch", "be", "es",]},
            types: ["postal_code", 'locality'],
            fields: ["address_components", "geometry"],
          })
          google.maps.event.addListener(autocomplete, "place_changed", () => {
            const result = autocomplete.getPlace();
            let biasCircle = new google.maps.Circle({
              center: result.geometry.location,
              radius: 100
            });
            autocomplete.setBounds(biasCircle.getBounds());
            let gps = autocomplete.getBounds()
            let obj = Object.values(gps)
            this.loc.longitude = obj[1].lo
            this.loc.latitude = obj[0].lo

            for (let component of result.address_components) {
              const componentType = component.types[0];
              switch (componentType) {
                case "postal_code":
                  this.loc.zip = component.long_name;
                  break;
                case "locality":
                  this.loc.city = component.long_name;
                  break;
                case "country":
                  this.loc.country = component.short_name;
                  break;
                case "administrative_area_level_1":
                  let region = component.long_name;
                  break;
                case "administrative_area_level_2":
                  let departement = component.long_name;
                  break;
              }
            }
          })
        })
      }
      return autocomplete
    },
  overlay(productIndex, show) {
			// update overlay property
			this.products[productIndex].showOverlay = show
		}
	},
	watch: {
		// watch for show overlay property
		products: {
			handler(updatedProducts){
				this.products = updatedProducts
			},
			deep: true,
			immediate: true,
		}},
  computed: {
    ...mapGetters('auth', ['getLocation', 'getIsAuthenticated']),

  },
  mounted() {
    this.completeCity();
  // Add dynamic show overlay property on each product
		this.products.forEach((product) => {
			this.$set(product, 'showOverlay', false)
		})
	}
}
</script>

<style lang="scss" scoped>
@use '@/scss/variables' as *;

section {
  width: 100%;
}

.product_cards {
  @include flex(flex-start, flex-start, row, wrap, $gap: 1rem .72rem);
  align-items: stretch;

  @media screen and (min-width: 992px) {
    justify-content: center;
    gap: 2vw;
  }

  .card {
    @include flex(flex-start, flex-start, column);
    color: #8BACD9;
    border-radius: 16px;
    outline: solid 1px $Brown;
    overflow: hidden;
    position: relative;
    box-shadow: 0 5px 15px -10px rgba(0, 0, 0, 1);
    transition: translate .2s, box-shadow .15s;
    width: calc(50% - .38rem);
    max-width: 300px;

    &.active-link:hover {
      transition: translate 1s, box-shadow .5s;
      translate: 0 -5px;
      box-shadow: 0 8.5px 44px -23.5px rgb(0 0 0);

      .card_title {
        transition: .2s ease;
        color: $Brown;
      }

      .card_product_img img {
        transition: 1s ease;
        scale: 1.1;
      }
    }

    &.inactive-link:hover, &.show-overlay {
      .card_overlay {
        opacity: 1;
      }
    }

    @media screen and (max-width: 350px) {
      width: 100%;
    }

    @media screen and (min-width: 576px) {
      max-width: calc(50% - 1vw);
      width: calc(50% - 1vw);
    }

    @media screen and (min-width: 768px) {
      max-width: calc(33% - 1.5vw);
    }

    @media screen and (min-width: 1200px) {
      max-width: calc(25% - calc(6vw / 4));
    }

    &_link {
      @include flex(flex-start, flex-start, column);
      width: 100%;
      height: 100%;
      text-decoration: none;
    }

    &_product_img {
      overflow: hidden;
      border-top-left-radius: 13px;
      border-top-right-radius: 13px;
      position: relative;
      z-index: -1;
      width: 100%;
      aspect-ratio: 1;

      img {
        border-top-left-radius: 13px;
        border-top-right-radius: 13px;
        width: 100%;
        scale: 1;
        will-change: scale;
        transition: .3s;
        aspect-ratio: 1;
        object-fit: contain;
      }
    }

    &_content {
      @include flex(flex-start, flex-start, column, $gap: .5rem);
      padding: .75rem;
      width: 100%;
      flex: 2;

      @media screen and (min-width: 576px) {
        padding: 5%;
        gap: .25rem;
      }
      @media screen and (min-width: 768px) {
        padding: 3% 5% 5%;
      }

      .card_title {
        text-transform: uppercase;
        text-align: left;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: .5px;
        color: $Black;

        @media screen and (min-width: 992px) {
          font-size: 1rem;
        }
      }

      .card_category {
        font-size: 12px;
        font-weight: lighter;
        text-align: left;
        color: $Grey;

        @media screen and (min-width: 768px) {
          font-size: 14px;
        }
      }

      .card_cart-container {
        @include flex(space-between, center, row, $gap: .5rem);
        align-self: flex-end;
        width: 100%;

        p {
          font-size: 14px;
          font-weight: lighter;
          color: $Grey;
          white-space: nowrap;
          @media screen and (min-width: 350px) and (max-width: 400px) {
            font-size: 3vw;
          }

          .product_price {
            color: $Black;
            font-weight: 600;
          }
        }
      }
    }

    &_overlay {
      @include position(0, 0);
      @include flex(flex-start, center, column);
      height: 100%;
      width: 100%;
      text-align: center;
      padding: .75rem;
      transition: .2s;
      background-color: rgba(255, 255, 255, .9);
      opacity: 0;

      @media screen and (min-width: 992px) {
        align-items: center;
      }

      .overlay__img {
        @include fixed-size(40px);

        @media screen and (min-width: 767px) {
          @include fixed-size(45px);
        }
        @media screen and (min-width: 992px) {
          @include fixed-size(65px)
        }
      }

      .overlay__text {
        @include flex(flex-start, center, column, $gap: 10px);
        flex: 1;
        width: 100%;
        color: $Black;

        p {
          font-size: 15px;
          color: #737373;

        }
      }

      .overlay__location {
        width: 100%;

        form {
          @include flex(center, stretch);
          width: 100%;

          .input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            line-height: 1;
            padding: 5px 10px;
            outline: none;
            color: $Black;

            &::placeholder {
              text-transform: uppercase;
              letter-spacing: 2px;
              text-align: center;
              font-size: 13px;
            }
          }

          .button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            padding: 7px 10px;
            line-height: 1;

            svg {
              @include fixed-size(10px)
            }
          }
        }
      }

      @media screen and (min-width: 578px) and (max-width: 767px) {
        .overlay__text {

          h3 {
            font-size: 25px;
          }

          p {
            font-size: 17px;
          }
        }

        .overlay__img {
          @include fixed-size(80px);
        }
      }

      @media screen and (min-width: 350px) and (max-width: 578px) {
        padding: 0.55rem;

        .overlay__text {
          justify-content: flex-start;

          h3 {
            font-size: 3.5vw;
          }

          p {
            font-size: 3vw;
          }
        }
      }
    }
  }
}
</style>